interface dataObjectProps {
  title?: string;
  link?: string;
  description?: string;
  terms?: string;
}

export const dataObjectCategoryMatcher = (
  dataObject: Record<string, dataObjectProps>,
  categoryId: string
) => {
  // If the data object returns with a matching category, we should return the matching category object
  if (
    dataObject[categoryId] &&
    Object.keys(dataObject[categoryId]).length > 0
  ) {
    return dataObject[categoryId];
  }

  // A data object with no matching category or an empty matching category, should fallback return the default object:
  return dataObject.default;
};

export const mergeDefaultAndCategoryDataObjects = (
  defaultObject: dataObjectProps,
  categoryObject: dataObjectProps
) => {
  return Object.assign(defaultObject, categoryObject);
};

import { ReactElement } from "react";
import { nanoid } from "nanoid";
import { ContentWidgetsArray } from "@/types/api/content";
import WidgetRenderer from "@/components/WidgetRenderer/WidgetRenderer";

interface Props {
  widgets: ContentWidgetsArray | undefined;
  contentLastUpdated: number;
  region?: string | null;
  pageTitle?: string;
}

export const WidgetMap = ({
  widgets,
  contentLastUpdated,
  region,
  pageTitle,
}: Props): ReactElement | null =>
  widgets ? (
    <>
      {widgets.map((widget) => (
        <WidgetRenderer
          widget={widget}
          key={nanoid()}
          contentLastUpdated={contentLastUpdated}
          region={region}
          pageTitle={pageTitle}
        />
      ))}
    </>
  ) : null;

import dynamic from "next/dynamic";
import Image from "next/image";
import {
  AccordionGroupProps,
  BettingTipProps,
  DataTableProps,
  EditorProps,
  MediaProps,
  OfferCardProps,
  TipsterProps,
  isEmptyObject,
  PpcTableOfferProps,
} from "ui";
import { PPC_TABLE_COMPONENT } from "ui/constants/content";
import { AllContentWidgets } from "@/types/api/content";
import { joinWordsWithHyphen } from "@/lib/string";
import { MeetingGridProps } from "@/components/MeetingGrid/MeetingGrid";
import { RaceSelectorProps } from "@/components/RaceSelector/RaceSelector";
import { checkAccordionHasContent } from "@/lib/content";
import { MatchOverviewProps } from "@/components/MatchOverview/MatchOverview";
import { LeagueTableProps } from "@/components/LeagueTable/LeagueTable";
import { SmartAccaCompetitionProps } from "@/components/SmartAccaCompetition/SmartAccaCompetition";
import { bannerLogoUrlFromName } from "@/lib/media";
import { StarIcon } from "@/components/icons/StarIcon";
import { SmartAccaMatchProps } from "@/components/SmartAccaMatch/SmartAccaMatch";
import { SuggestedSmartAccaProps } from "@/components/SuggestedSmartAcca/SuggestedSmartAcca";
import { LatestContentProps } from "@/components/LatestContent/LatestContent";
import { YoutubeEmbedProps } from "@/components/YoutubeEmbed/YoutubeEmbed";
import { ViewAllLink } from "@/components/ViewAllLink/ViewAllLink";
import { MatchCenterProps } from "@/components/MatchCenter/MatchCenter";
import { RelatedContentProps } from "@/components/RelatedContent/RelatedContent";
import { faFormattedCompetitionNamesMap } from "@/constants/competitions";
import {
  dataObjectCategoryMatcher,
  mergeDefaultAndCategoryDataObjects,
} from "@/lib/ppc";

const DynamicLatestContent = dynamic<LatestContentProps>(() =>
  import("@/components/LatestContent/LatestContent").then(
    (mod) => mod.LatestContent
  )
);
const DynamicRelatedContent = dynamic<RelatedContentProps>(() =>
  import("@/components/RelatedContent/RelatedContent").then(
    (mod) => mod.RelatedContent
  )
);

const DynamicCardWrapper = dynamic(
  () => import("@/components/CardWrapper/CardWrapper")
);
const DynamicOfferCard = dynamic<OfferCardProps>(() =>
  import("ui").then((mod) => mod.OfferCard)
);
const DynamicAuthor = dynamic(() => import("@/components/Author/Author"));
const DynamicPredictionCard = dynamic(
  () => import("@/components/PredictionCard/PredictionCard")
);
const DynamicEditor = dynamic<EditorProps>(() =>
  import("ui").then((mod) => mod.Editor)
);
const DynamicCardUl = dynamic(
  () => import("@/components/WidgetRenderer/WidgetRenderer.style")
);
const DynamicMeetingGrid = dynamic<MeetingGridProps>(() =>
  import("@/components/MeetingGrid/MeetingGrid").then((mod) => mod.MeetingGrid)
);
const DynamicMatchOverview = dynamic<MatchOverviewProps>(() =>
  import("@/components/MatchOverview/MatchOverview").then(
    (mod) => mod.MatchOverview
  )
);
const DynamicMedia = dynamic<MediaProps>(() =>
  import("ui").then((mod) => mod.Media)
);
const DynamicAccordionGroup = dynamic<AccordionGroupProps>(() =>
  import("ui").then((mod) => mod.AccordionGroup)
);
const DynamicDataTable = dynamic<DataTableProps>(() =>
  import("ui").then((mod) => mod.DataTable)
);
const DynamicLeagueTable = dynamic<LeagueTableProps>(() =>
  import("@/components/LeagueTable/LeagueTable").then((mod) => mod.LeagueTable)
);
const DynamicSmartAccaCompetition = dynamic<SmartAccaCompetitionProps>(() =>
  import("@/components/SmartAccaCompetition/SmartAccaCompetition").then(
    (mod) => mod.SmartAccaCompetition
  )
);
const DynamicSmartAccaMatch = dynamic<SmartAccaMatchProps>(() =>
  import("@/components/SmartAccaMatch/SmartAccaMatch").then(
    (mod) => mod.SmartAccaMatch
  )
);
const DynamicSuggestedSmartAcca = dynamic<SuggestedSmartAccaProps>(() =>
  import("@/components/SuggestedSmartAcca/SuggestedSmartAcca").then(
    (mod) => mod.SuggestedSmartAcca
  )
);
const DynamicBettingTip = dynamic<BettingTipProps>(() =>
  import("ui").then((mod) => mod.BettingTip)
);
const DynamicTipster = dynamic<TipsterProps>(() =>
  import("@/components/Tipster").then((mod) => mod.Tipster)
);
const DynamicBettingIframe = dynamic(() =>
  import("@/components/BettingIframe/BettingIframe").then(
    (mod) => mod.BettingIframe
  )
);
const DynamicOtherTips = dynamic(() =>
  import("@/components/OtherTips/OtherTips").then((mod) => mod.OtherTips)
);

const DynamicYoutubeEmbed = dynamic<YoutubeEmbedProps>(() =>
  import("@/components/YoutubeEmbed/YoutubeEmbed").then(
    (mod) => mod.YoutubeEmbed
  )
);

const DynamicRaceSelector = dynamic<RaceSelectorProps>(() =>
  import("@/components/RaceSelector/RaceSelector").then(
    (mod) => mod.RaceSelector
  )
);

const DynamicMatchCenter = dynamic<MatchCenterProps>(() =>
  import("@/components/MatchCenter/MatchCenter").then((mod) => mod.MatchCenter)
);

const DynamicPpcTable = dynamic<PpcTableOfferProps>(() =>
  import("ui").then((mod) => mod.PpcTable)
);

interface Props {
  widget: AllContentWidgets;
  contentLastUpdated: number;
  region?: string | null;
  pageTitle?: string;
}

const WidgetRenderer = ({
  widget,
  contentLastUpdated,
  region,
  pageTitle,
}: Props): JSX.Element | null => {
  switch (widget.component) {
    // Widgets in old content but no longer supported
    case "FeatureSlider":
    case "AppPromoBanner":
    case "SkyPlayer":
    case "Playbuzz":
    case "RelatedNews":
      return null;
    case "LatestNews":
      return (
        <DynamicLatestContent
          content={widget.data}
          listHeader={"Latest News"}
          region={region}
        />
      );
    case "LatestQuizzes":
      return (
        <DynamicLatestContent
          content={widget.data}
          listHeader={"Latest Quizzes"}
        />
      );
    case "Offer":
      // The offer's endpoint allows multiple offers to be requested and returns an array
      // As we request the widget data per widget this will always have a length of 1
      return widget.data && widget.data.length > 0 ? (
        <DynamicOfferCard offer={widget.data[0]} />
      ) : null;
    case "LatestPredictions":
      const listHeader = widget.competition?.name
        ? `Latest ${widget.competition.name} Predictions`
        : "Latest Predictions";

      // Fix for some competition names not being the correct paths to pages
      // e.g. League 1 should be /predictions/league-one not /predictions/league-1
      const getFormattedCompetitionName = (name?: string) => {
        if (!name) return undefined;

        const hyphenatedName = joinWordsWithHyphen(name);
        return (
          faFormattedCompetitionNamesMap.get(hyphenatedName) ?? hyphenatedName
        );
      };

      const formattedCompetitionName = getFormattedCompetitionName(
        widget.competition?.name
      );

      const contentPath = formattedCompetitionName
        ? `/predictions/${formattedCompetitionName}`
        : "/predictions";

      return widget.data && widget.data.length > 0 ? (
        <DynamicCardWrapper
          listHeader={listHeader}
          headerChildren={<ViewAllLink allContentPath={contentPath} />}
        >
          <DynamicCardUl
            noPadding={true}
            aria-labelledby={joinWordsWithHyphen(
              `card list Latest Predictions`
            )}
          >
            {widget.data.map((prediction) => (
              <DynamicPredictionCard
                key={prediction._id}
                prediction={prediction}
              />
            ))}
          </DynamicCardUl>
        </DynamicCardWrapper>
      ) : null;
    case "Editor": {
      return (
        <DynamicCardWrapper
          hideHeader={!widget.title}
          listHeader={widget.title || ""}
        >
          <DynamicEditor formattedContent={widget.formattedContent} />
        </DynamicCardWrapper>
      );
    }
    case "Author":
      return widget.activeAuthor ? (
        <DynamicAuthor
          activeAuthor={widget.activeAuthor}
          contentLastUpdated={contentLastUpdated}
        />
      ) : null;
    case "MeetingGrid":
      return widget.data ? (
        <DynamicMeetingGrid raceMeetings={widget.data} />
      ) : null;
    case "MatchOverview":
      return widget.data && widget.data.prediction.match ? (
        <DynamicMatchOverview data={widget.data} />
      ) : null;
    case "Media":
      return widget.slug ? (
        <DynamicMedia
          slug={widget.slug}
          caption={widget.caption}
          alt={widget.alt}
          url={widget.url}
          target={widget.target}
        />
      ) : null;
    case "Accordion":
      return checkAccordionHasContent(widget) && widget.title ? (
        <DynamicCardWrapper listHeader={widget.title}>
          <DynamicAccordionGroup questions={widget.questions} />
        </DynamicCardWrapper>
      ) : null;
    case "DataTable":
      return widget.leaderboard ? (
        <DynamicDataTable data={widget.leaderboard} />
      ) : null;
    case "LeagueTable":
      return widget.data ? (
        <DynamicLeagueTable
          data={widget.data}
          tableType={widget.type}
          competition={widget.competition}
          table={widget.table}
          groupTable={widget.group}
          team={widget.team}
        />
      ) : null;
    case "SmartAccaCompetition":
      const { market, type, competitionId, data } = widget;
      const teams = data?.teams || [];
      const results = data?.results || [];

      return competitionId ? (
        <DynamicSmartAccaCompetition
          market={market}
          type={type}
          competitionId={competitionId}
          teams={teams}
          results={results}
        />
      ) : null;
    case "SmartAccaMatch":
      const event =
        widget.data &&
        widget.data.event &&
        Object.keys(widget.data.event).length !== 0
          ? widget.data.event
          : null;

      const markets =
        widget.data && widget.data.markets && widget.data.markets.length > 0
          ? widget.data.markets
          : null;

      return widget.fixtureID && widget.basedOn && event && markets ? (
        <DynamicSmartAccaMatch
          event={event}
          markets={markets}
          statSelection={widget.basedOn}
        />
      ) : null;
    case "SuggestedSmartAcca": {
      const accas = widget.data && widget.data.accas ? widget.data.accas : [];

      return accas.length > 0 ? (
        <DynamicSuggestedSmartAcca accas={accas} />
      ) : null;
    }
    case "CodeBlock":
      return widget.code ? (
        <DynamicEditor formattedContent={widget.code} />
      ) : null;
    case "BettingTip": {
      const { title, rating, bookmaker, tip, odds, url, reason, cta, data } =
        widget;

      let site_id = "noBookmakerPredictionCode";

      if (data && bookmaker && data[bookmaker]) {
        site_id = data[bookmaker];
      }

      return title && bookmaker && tip && odds && url ? (
        <DynamicCardWrapper
          listHeader={title}
          icon={rating && rating === 1 ? <StarIcon /> : null}
          headerChildren={
            <Image
              src={bannerLogoUrlFromName(bookmaker)}
              alt={`${bookmaker} logo`}
              fill
            />
          }
        >
          <DynamicBettingTip
            bookmaker={bookmaker}
            tip={tip}
            odds={odds}
            url={url}
            reason={reason}
            cta={cta}
            site_id={site_id}
          />
        </DynamicCardWrapper>
      ) : null;
    }
    case "RelatedContent":
      return widget.data && widget.data.length > 0 ? (
        <DynamicRelatedContent
          title={widget.widgetTitle}
          data={widget.data}
          author={widget.author}
          authorData={widget.authorData}
          region={region}
        />
      ) : null;
    case "BettingIframe": {
      const { source } = widget;
      if (!source) return null;
      return <DynamicBettingIframe source={source} />;
    }
    case "Tipster":
      const { tip_category_id, brand_id } = widget;

      if (!tip_category_id || !brand_id || !widget.data) return null;

      return (
        <DynamicTipster
          tipsterData={widget.data}
          tipCategoryId={tip_category_id}
        />
      );
    case "Youtube":
      const { video_id } = widget;
      if (!video_id) return null;
      return <DynamicYoutubeEmbed video_id={video_id} />;
    case "OtherTipsInfo":
      if (!widget.data) return null;

      return <DynamicOtherTips otherTips={widget.data} />;
    case "RaceSelector":
      if (!widget.data || !widget.data.raceInfo.length) return null;
      return (
        <DynamicRaceSelector
          raceInfo={widget.data.raceInfo[0]}
          runners={widget.data.runners}
          result={widget.data.result}
        />
      );
    case "MatchCenter":
      const { initialCompetition, initialMarket } = widget;

      return initialCompetition &&
        initialMarket &&
        widget.data &&
        widget.data.events ? (
        <DynamicMatchCenter
          initialCompetition={initialCompetition}
          initialMarket={initialMarket}
          events={widget.data.events}
        />
      ) : null;

    case "TwitterCard":
      const { formattedContent } = widget;
      if (!formattedContent) return null;
      return <DynamicEditor formattedContent={widget.formattedContent} />;

    case PPC_TABLE_COMPONENT:
      if (isEmptyObject(widget.data) || !widget.data) return null;

      // We change the shape of the data here and apply the offer formatting before we render the widget
      const { ppc_category_id } = widget;

      const ppcTableOffers = widget.data.map((offer) => {
        const { bookmaker, meta } = offer;
        const { name_cleansed, name, tracking_parameter } = bookmaker;
        const { cta, data, score, features } = meta;

        // Use dataObjectCategoryMatcher to determine if we should be returning the matching category object or the default one
        const categoryData = dataObjectCategoryMatcher(data, ppc_category_id);

        // We merge both the default and category data objects together and we get the link, title or terms from here to pass down
        // to the meta object as part of the data passed down to the PPC Table
        const mergedData = mergeDefaultAndCategoryDataObjects(
          data.default,
          categoryData
        );

        return {
          bookmaker: {
            name_cleansed,
            name,
            tracking_parameter,
          },
          meta: {
            cta,
            link: mergedData.link ?? "",
            title: mergedData.title ?? "",
            terms_and_conditions: mergedData.terms ?? "",
            score,
            features,
          },
        };
      });

      return (
        <DynamicPpcTable
          ppcTableOffers={ppcTableOffers}
          listHeader={pageTitle ?? ""}
        />
      );

    default:
      return (
        <div>
          {/* @ts-expect-error We should never hit the statement below but its possible we have unsupported widgets, this will help highlight if we do */}
          <h2>{widget.component} needs to be added</h2>
        </div>
      );
  }
};

export default WidgetRenderer;

import { ReactElement } from "react";
import styles from "@/components/ViewAllLink/ViewAllLink.module.scss";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import AngleRightIcon from "@/components/icons/AngleRightIcon";
import { viewAllTranslations } from "./localisedTranslations";

interface ViewAllLinkProps {
  allContentPath?: string;
  region?: string | null;
}

export const ViewAllLink = ({
  allContentPath,
  region,
}: ViewAllLinkProps): ReactElement | null => {
  return allContentPath ? (
    <div className={styles.viewAllLink}>
      <LinkNoPrefetch href={allContentPath}>
        {viewAllTranslations.get(region ?? "default") ?? "View All"}
        <AngleRightIcon className={styles.arrowRight} />
      </LinkNoPrefetch>
    </div>
  ) : null;
};

import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

interface IconProps {
  className?: string;
}

export const StarIcon = ({ className }: IconProps): ReactElement => {
  return (
    <FontAwesomeIcon
      icon={faStar}
      className={className}
      data-testid="starIcon"
    />
  );
};
